import React, {useEffect, useState} from "react";
import {Card, Col, Row, Statistic} from "antd";
import {Link} from "react-router-dom";
import Api from '../api';

export default () => {
    const [caseCounts, setCaseCounts] = useState(null);

    useEffect(() => {
        (async function getCaseCounts() {
            const counts = await Api.getCaseCounts();
            setCaseCounts(counts)
        })();
    }, [])


    return <>
        <Row>
            <Col offset={8} span={8}>
                <Card title="Ваши предмети" style={{marginTop: 50, marginBottom: 50}}>
                    <Row>
                        <Col span={12}>
                            <Link to="/cases">
                                <Statistic title="Активних предмета" value={caseCounts === null ? "-" : caseCounts.active}/>
                            </Link>
                        </Col>
                        <Col span={12}>
                            <Link to="/inactive-cases">
                                <Statistic title="Неактивних предмета" value={caseCounts === null ? "-" : caseCounts.inactive}/>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>
}

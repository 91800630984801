import React, {useState, useEffect} from 'react';
import {Form, Input, Button, message, Modal, Table, Spin} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import Api from '../api';
const {confirm} = Modal;


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 6,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};


export default props => {
    const [users, setUsers] = useState(null);
    const updateUsers = () => {
        Api.getUsers().then(response => {
            setUsers(response.data);
        });
    };
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
        },
        {
            title: 'Actions',
            render: record => <Button type="danger" onClick={() => confirmDelete(record)}>Delete</Button>
        }
    ];

    const confirmDelete = record => {
        confirm({
            title: `Deleting ${record.username}`,
            icon: <QuestionCircleOutlined/>,
            content: `Are you sure that you want to delete this user? You cannot undo this action.`,
            okText: 'Delete',
            onOk() {
                Api.deleteUser(record._id).then(() => {window.setTimeout(updateUsers, 200)});
            }
        });
    }


    const onFinish = ({username, password1, password2}) => {
        if (password1!==password2) {
            message.error("The passwords you entered did not match");
            return;
        }
        try {
            Api.addUser({username, password: password1})
                .then(r => {console.log({r});window.setTimeout(updateUsers, 200)})
                .catch(e => {
                    const errorMessage = e.response.data.error;
                    message.error("Could not add this user. "+errorMessage);
            });
        } catch(e) {
            alert("Error adding the user.");
        }

    };

    useEffect(updateUsers, []);
    if (users === null) return <Spin/>

    return (<>
            <h2>Add User</h2>
            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please, enter the username',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="password1"
                    rules={[
                        {
                            required: true,
                            message: 'Please, enter the password',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    label="Repeat"
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: 'Please repeat the password.',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <h2>Existing Users</h2>
            <Table dataSource={users} columns={columns} locale={{emptyText: 'No users'}}/>
        </>
    );
};

import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";
const TOKEN_REFRESH_INTERVAL = 60 * 1000;


class Api {
    constructor() {
        this.client = axios.create({baseURL, withCredentials: true});
        this.client.interceptors.request.use(request => {
            request.headers = {...request.headers, Authorization: `Bearer ${this.accessToken}`};
            return request;
        });

        this.client.interceptors.response.use(response => response, (e) => {
            if (!e.config.url.includes("auth/refreshToken") && (e.response.status===401 || e.response.status===403)) {
                const originalRequest = e.config;
                return this.client.get('/auth/refreshToken').then(response => {
                    this.accessToken = response.data.token;
                    this.availablePrefixes = response.data.availablePrefixes;
                    originalRequest.headers.Authorization = `Bearer ${this.accessToken}`;
                    return this.client.request(originalRequest);
                }).catch(() => Promise.reject(e)); // returning original error if token refresh fails
            }
            return Promise.reject(e);
        });


        this.accessToken = null;
        this.availablePrefixes = null;
        window.setInterval(() => this._refreshToken(), TOKEN_REFRESH_INTERVAL);
    }

    _refreshToken() {
        if (this.accessToken) {
            this.client.get('/auth/refreshToken').then(response => {
                this.accessToken = response.data.token;
                this.availablePrefixes = response.data.availablePrefixes;
            });
        }
    }

    getBaseUrl() {
        return baseURL;
    }

    checkSession() {
        return this.client.get('auth/check');
    }

    async doLogin(username, password) {
        try {
            const response = await this.client.post('auth/login', {username, password});
            this.accessToken = response.data.token;
            this.availablePrefixes = response.data.availablePrefixes;
            return response;
        } catch (e) {
            throw new Error("Authentication failed");
        }
    }

    async doLogout() {
        try {
            const response = await this.client.get('auth/logout');
            this.accessToken = null;
            return response;
        } catch (e) {
            throw new Error("Logout failed");
        }
    }

    async doChangePass(password) {
        try {
            return await this.client.post('auth/updatePassword', {password});
        } catch (e) {
            throw new Error("Could not change the password.");
        }
    }

    async getUsers() {
        try {
            return this.client.get('auth/user');
        } catch (e) {
            throw new Error("Can't get users");
        }
    }

    deleteUser(id) {
        return this.client.delete(`/auth/user/${id}`);
    }

    addUser({username, password}) {
        return this.client.post(`/auth/user`, {username, password});
    }

    async getCaseCounts() {
        const response = await this.client.head("/case");
        return {
            active: response.headers["x-active-count"],
            inactive: response.headers["x-inactive-count"],
            total: response.headers["x-total-count"],
        }
    }

    async getCases(activeOnly=false) {
        if (activeOnly) return this.client.get("/case?activeOnly=true");
        return this.client.get("/case");
    }

    async postCase(data) {
        return this.client.post("/case", data);
    }

}

export default new Api();

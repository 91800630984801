import React, {useState, useEffect} from 'react';
import {Button, PageHeader, Table, Empty} from 'antd';
import {CheckOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Api from '../api';

const {Column} = Table;

function formatDateTime(timestamp) {
    if (timestamp === null) {
        return "-";
    }
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString("sr-RS");
    const formattedTime = date.toLocaleTimeString("sr-RS");
    return `${formattedDate} ${formattedTime}`;
}

function formatStatus(record) {
    if (record.caseStatus === "submitted") return "У току";
    if (record.caseStatus === "resolved") {
        return "Донета одлука регистратора";
    }
}

function formatActions(record) {
    if (record.link) return <Button href={record.link} icon={<DownloadOutlined/>}>Преузми одлуку</Button>;
}

export default () => {
    const [cases, setCases] = useState(null);
    const [activeOnly, setActiveOnly] = useState(true);
    const history = useHistory();
    useEffect(() => {
        setCases(null);
        Api.getCases(activeOnly).then(response => setCases(response.data));
    }, [activeOnly])

    return <><PageHeader title="Предмети" extra={[
        <Button icon={activeOnly ? <CheckOutlined/> : null} onClick={() => setActiveOnly(true)}>Само активни
            предмети</Button>,
        <Button icon={activeOnly ? null : <CheckOutlined/>} onClick={() => setActiveOnly(false)}>Сви предмети</Button>,
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => history.push("/cases/add")}>Додавање предмета</Button>
    ]}/>
        <Table loading={cases===null} dataSource={cases} locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={activeOnly ? "Нема активних предмета" : "Нема предмета"}/>}}>
            <Column title="Број предмета" dataIndex="caseNumber"/>
            <Column title="Прати се од" dataIndex="createdAt" render={formatDateTime}/>
            <Column title="Последња провера" dataIndex="lastCheck" render={formatDateTime}/>
            <Column title="Активан" dataIndex="active" render={value => value ? "Да" : "Не"}/>
            <Column title="Статус" render={formatStatus}/>
            <Column align="right" title="Акције" render={formatActions}/>
        </Table>
    </>
}

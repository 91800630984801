import React from 'react';
import {PageHeader, Form, Input, Select, Button, message} from "antd";
import { SendOutlined } from '@ant-design/icons';
import Api from '../api';
import {useHistory} from "react-router-dom";
const { Option } = Select;

export default () => {
    const history = useHistory();

    const prefixSelector = (
        <Form.Item name="casePrefix" noStyle>
            <Select style={{ width: 70 }}>
                {Api.availablePrefixes.map(prefix => <Option value={prefix} key={prefix}>{prefix}</Option>)}
            </Select>
        </Form.Item>
    );

    const submitJob = async values => {
        try {
            await Api.postCase(values);
            message.success(`Почиње праћење предмета број ${values.casePrefix} ${values.caseNumber}.`);
            history.push("/cases");
        } catch (e) {
            message.error("Додавање предмета није успело.");
        }
    }

    const [form] = Form.useForm();
    return <><PageHeader title="Додавање новог предмета"/>
        <Form form={form} layout="inline" onFinish={submitJob} initialValues={{ casePrefix: 'БД'}}>
            <Form.Item name="caseNumber" hasFeedback label="Број предмета" rules={[
                {required: true, message: "Поље је обавезно"},
                {pattern: /^\d+\/20\d{2}$/, message: "Користите формат 12345/"+(new Date()).getFullYear()}
                ]}>
                <Input
                    addonBefore={prefixSelector}
                    />
            </Form.Item>
            <Button type="primary" htmlType="submit"><SendOutlined/></Button>
        </Form>
        </>
}

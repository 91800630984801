import React from 'react';
import { Form, Input, Button, message } from 'antd';
import {useHistory} from 'react-router-dom';
import Api from '../api';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 6,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

export default props => {
    const history = useHistory();
    const onFinish = ({username, password}) => {
        Api.doLogin(username, password).then(response => {
            message.success('Добродошли!');
            props.cb(true);
            history.push("/");
        }).catch(e => {
            message.error('Неуспешна пријава. Да ли сте ми дали исправну лозинку?');
        })
    };

    return (
        <Form
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Корисничко име"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Молим да унесете корисничко име.',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Лозинка"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Молим да унесете лозинку.',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Улаз
                </Button>
            </Form.Item>
        </Form>
    );
};

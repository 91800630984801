import React from 'react';
import { Form, Input, Button, message, Modal } from 'antd';
import {useHistory} from 'react-router-dom';
import Api from '../api';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 6,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

export default props => {
    const history = useHistory();
    const onFinish = ({password1, password2}) => {
        if (password1!==password2) {
            message.error("Лозинке се разликују. Покушајте поново.");
            return;
        }

        Api.doChangePass(password1).then(response => {
            Modal.info({
                title: 'Password updated',
                content: (<div><p>Сачувана је нова лозинка. Из безбедносних разлога, сад ћемо да Вас одјавимо са свих уређаја на којима сте пријављени. Молимо да се пријавите користећи Вашу нову лозинку.</p></div>),
                onOk() {
                    props.cb(false);
                    history.push("/");
                }
            })
        }).catch(e => {
            message.error('Грешка приликом чувања нове лозинке, стара лозинка је и даље у употреби.');
        })

    };

    return (<>
        <h2>Промена лозинке</h2>
        <Form
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Нова лозинка"
                name="password1"
                rules={[
                    {
                        required: true,
                        message: 'Молим Вас да унесете нову лозинку',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Поново"
                name="password2"
                rules={[
                    {
                        required: true,
                        message: 'Молим да поново укуцате нову лозинку.',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Промени
                </Button>
            </Form.Item>
        </Form>
        </>
    );
};

import React, {useEffect, useState} from 'react';
import './App.css';
import Api from './api';
import {FileTextFilled, LogoutOutlined, LockFilled} from '@ant-design/icons';
import { Layout, Menu, Spin, message } from 'antd';
import {Switch, Route, Redirect, Link} from 'react-router-dom';
import Login from './Login';
import Profile from './Profile';
import Users from './Users';
import Dashboard from './Dashboard';
import Cases from './Cases/index';
const {Header, Content, Footer, Sider} = Layout;

function App() {
  // const [selectedKey, setSelectedKey] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  // const location = useLocation();


  function doLogout() {
    Api.doLogout();
    setIsLoggedIn(false);
    message.success("Одјављени сте.");
  }

  useEffect(() => {
    Api.checkSession()
        .then(() => setIsLoggedIn(true))
        .catch(() => setIsLoggedIn(false));
  }, [])

  return (
      <div className="App">
        <Layout style={{minHeight: "100vh"}}>
          <Sider
              breakpoint="lg"
              collapsedWidth="0"
              style={{backgroundColor: "rgb(0, 96, 169)"}}
          >
            <Link to="/">
              <div id="logo">Мониторо</div>
            </Link>
            {isLoggedIn &&
            <Menu theme="light" mode="inline" style={{paddingTop: 40, paddingBottom: 40}}
                  selectedKeys={[]}>
              <Menu.Item key="1">
                <Link to="/cases">
                  <FileTextFilled/>
                  <span className="nav-text">Предмети</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/profile">
                  <LockFilled/>
                  <span className="nav-text">Промена лозинке</span>
                </Link>
              </Menu.Item>
              {/*<Menu.Item key="3">*/}
              {/*  <Link to="/users">*/}
              {/*    <UserOutlined/>*/}
              {/*    <span className="nav-text">Корисници</span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
              <Menu.Item key="4">
                <Link onClick={doLogout}>
                  <LogoutOutlined/>
                  <span className="nav-text">Одјава</span>
                </Link>
              </Menu.Item>
            </Menu>}
          </Sider>
          <Layout>
            <Header className="site-layout-sub-header-background" style={{padding: 0}}/>
            <Content style={{margin: '24px 16px 0'}}>
              <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>

                <Switch>
                  <Route exact path="/login">
                    <Login cb={setIsLoggedIn}/>
                  </Route>
                  {isLoggedIn ? <>
                    <Route exact path="/profile">
                      <Profile cb={setIsLoggedIn}/>
                    </Route>
                    <Route exact path="/users">
                      <Users/>
                    </Route>
                    <Route path="/cases">
                      <Cases/>
                    </Route>
                    <Route exact path="/">
                      <Dashboard/>
                    </Route> </> : isLoggedIn === null ? <Spin/> : <Redirect to={"/login"}/>}
                </Switch>
              </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>&copy; {(new Date()).getFullYear()} Мониторо</Footer>
          </Layout>
        </Layout>
      </div>
  );
}

export default App;
